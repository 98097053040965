<template>
    <div id="plan-mapper-wrapper">
        <select name="" v-model="floor">
            <option v-for="(f,i) in floors" :value="i" :key="i">{{f.name}}</option>
        </select><br>
        <div id="plan-mapper-container">
            <canvas id="plan-mapper-canvas" @mousemove="showCoordinates"></canvas>
            <img usemap="#map" id="plan-mapper-img" :src="floor_image()" alt="" v-if="loaded">
            <div id="plan-mapper-infobg" v-show="clicked_area_id!==null" @click="reset_clicked">
                <div id="plan-mapper-info" @click="reset_clicked">
                    <h3>{{floors[floor].type}} № {{clicked_group_key}}</h3>
                    <p>{{clicked_group_text}}</p>
                </div>
            </div>
        </div>
        <map name="map">
            <area href="#" v-for="(a,i) in areas" :key="i" :alt="group_title(a['group'])"
                  :title="area_square_info(a['title'])" :shape="a['shape']" :coords="a['coords']"
                  @click.prevent="_click(i)" @mouseenter="_mouseenter(i)" @mouseleave="_mouseleave(i)" />
        </map>
<!--        hovered_area_id {{ hovered_area_id }}<br>-->
<!--        hovered_area_key {{ hovered_area_key }}<br>-->
<!--        hovered_group_key {{ hovered_group_key }}<br>-->
<!--        clicked_area_id {{ clicked_area_id }}<br>-->
<!--        clicked_area_key {{ clicked_area_key }}<br>-->
<!--        clicked_group_key {{ clicked_group_key }}<br>-->
<!--        clicked_group_text {{ clicked_group_text }}<br>-->
    </div>
</template>


<script>

    import jsonfile from './assets/defaultgroups.json';

    const IMAGE_WIDTH=2763;
    const IMAGE_HEIGHT=3062;
    const IMAGE_SCREEN_WIDTH=690;
    const IMAGE_SCREEN_HEIGHT=765;
    const FLOORS=5;
    const JSON5 = require('json5')
    export default {
        name: 'HousePlanMapper',
        props: {
            msg: String
        },
        data: function () {
            return {
                floors: [
                    {name:'1-й этаж', img:require('./assets/plan1.png'), type:'Помещение'},
                    {name:'2-й этаж', img:require('./assets/plan2.png'), type:'Помещение'},
                    {name:'3-й этаж', img:require('./assets/plan3.png'), type:'Помещение'},
                    {name:'подвал',   img:require('./assets/plan0.png'), type:'Помещение'},
                    {name:'парковка', img:require('./assets/planP.png'), type:'Место'},
                ],
                floor:0,
                canvas:null,
                ctx:null,
                mouseX:null,
                mouseY:null,
                all_groups: jsonfile,
                all_areas: [
                    [
                        {shape:"rect", _coords:"1904, 182, 2097, 427", title:"61"},
                        {shape:"rect", _coords:"1904, 432, 1965, 529", title:"60"},
                        {shape:"rect", _coords:"1971, 432, 2066, 543", title:"70"},
                        {shape:"rect", _coords:"2105, 182, 2280, 354", title:"62"},
                        {shape:"rect", _coords:"1536, 2208, 1699, 2330", title:"6"},
                        {shape:"rect", _coords:"1535, 2459, 1697, 2552", title:"5"},
                        {shape:"rect", _coords:"1162, 2207, 1340, 2552", title:"34"},
                        {shape:"rect", _coords:"1067, 2442, 1142, 2551", title:"32"},
                        {shape:"rect", _coords:"1066, 2339, 1142, 2434", title:"33"},
                        {shape:"rect", _coords:"978, 2340, 1060, 2551", title:"28"},
                        {shape:"rect", _coords:"1046, 2272, 1143, 2331", title:"31"},
                        {shape:"rect", _coords:"1046, 2207, 1143, 2263", title:"30"},
                        {shape:"rect", _coords:"979, 2207, 1038, 2332", title:"29"},
                        {shape:"poly", _coords:"2073, 525, 2072, 432, 2104, 433, 2106, 363, 2281, 363, 2280, 525, 2250, 525, 2250, 543, 2128, 544, 2127, 524", title:"63"},
                        {shape:"poly", _coords:"2288, 182, 2624, 183, 2625, 359, 2402, 359, 2403, 529, 2287, 528", title:"64"},
                        {shape:"poly", _coords:"2410, 367, 2624, 368, 2624, 528, 2597, 528, 2596, 542, 2478, 542, 2477, 528, 2409, 528", title:"65"},
                        {shape:"poly", _coords:"1568, 1097, 1789, 1097, 1790, 1261, 1635, 1259, 1569, 1190", title:"76"},
                        {shape:"poly", _coords:"1718, 549, 1728, 548, 1728, 539, 1860, 539, 1860, 549, 1878, 549, 1878, 625, 1718, 625", title:"57"},
                        {shape:"poly", _coords:"2400, 2571, 2624, 2571, 2624, 2745, 2443, 2747, 2442, 2663, 2398, 2663", title:"77"},
                        {shape:"poly", _coords:"2006, 2289, 2007, 2207, 2260, 2208, 2260, 2551, 2251, 2551, 2250, 2566, 2116, 2566, 2116, 2553", title:"12"},
                        {shape:"rect", _coords:"1902, 2208, 2000, 2285", title:"11"},
                        {shape:"rect", _coords:"1718, 2208, 1902, 2400", title:"10"},
                        {shape:"poly", _coords:"1908, 2292, 2000, 2291, 2108, 2549, 2053, 2549, 2053, 2569, 2391, 2569, 2391, 2661, 1903, 2661, 1904, 2569, 1930, 2569, 1930, 2550, 1908, 2550", title:"7"},
                        {shape:"poly", _coords:"987, 2569, 1884, 2571, 1884, 2664, 1511, 2664, 1511, 2916, 1336, 2917, 1336, 2662, 987, 2663", title:"4"},
                        {shape:"poly", _coords:"611, 2443, 882, 2442, 881, 2304, 972, 2304, 971, 2552, 941, 2552, 941, 2572, 970, 2573, 969, 2662, 771, 2665, 771, 2571, 809, 2571, 809, 2552, 767, 2553, 767, 2565, 637, 2565, 637, 2552, 611, 2552", title:"20"},
                        {shape:"poly", _coords:"610, 2208, 874, 2208, 874, 2434, 680, 2435, 681, 2278, 611, 2278", title:"26"},
                        {shape:"rect", _coords:"226, 2207, 590, 2552", title:"24"},
                        {shape:"poly", _coords:"1568, 1893, 1634, 1827, 1790, 1827, 1790, 2188, 1567, 2187", title:"3"},
                        {shape:"poly", _coords:"1446, 911, 1560, 912, 1560, 1194, 1632, 1265, 1790, 1265, 1790, 1447, 1674, 1447, 1675, 1632, 1790, 1631, 1792, 1821, 1632, 1821, 1562, 1893, 1561, 2187, 1517, 2187, 1517, 2551, 1494, 2552, 1495, 2572, 1380, 2571, 1380, 2552, 1358, 2553, 1359, 2207, 1446, 2207", title:"2"},
                        {shape:"poly", _coords:"1074, 1646, 1427, 1645, 1427, 1671, 1440, 1671, 1441, 1785, 1407, 1787, 1407, 1851, 1442, 1852, 1440, 1971, 1406, 1971, 1406, 2037, 1440, 2038, 1440, 2110, 1380, 2109, 1380, 2187, 1074, 2187", title:"35"},
                        {shape:"rect", _coords:"1074, 1530, 1138, 1639", title:"36"},
                        {shape:"poly", _coords:"1073, 1087, 1155, 1087, 1155, 1019, 1238, 1018, 1240, 912, 1426, 913, 1427, 938, 1439, 939, 1440, 1063, 1426, 1063, 1426, 1123, 1440, 1123, 1440, 1248, 1426, 1248, 1426, 1468, 1406, 1468, 1407, 1639, 1144, 1639, 1145, 1528, 1269, 1528, 1268, 1463, 1075, 1463", title:"37"},
                        {shape:"poly", _coords:"1074, 911, 1232, 912, 1233, 1012, 1146, 1011, 1145, 1080, 1074, 1080", title:"38"},
                        {shape:"rect", _coords:"1682, 1455, 1790, 1623", title:"1"},
                        {shape:"poly", _coords:"2469, 739, 2508, 700, 2624, 700, 2625, 893, 2470, 893", title:"67"},
                        {shape:"poly", _coords:"2282, 700, 2428, 700, 2464, 739, 2464, 892, 2282, 893", title:"68"},
                        {shape:"poly", _coords:"2283, 548, 2624, 548, 2625, 692, 2505, 691, 2466, 731, 2428, 692, 2282, 694", title:"66"},
                        {shape:"poly", _coords:"2104, 704, 2147, 663, 2262, 662, 2262, 892, 2105, 893", title:"69"},
                        {shape:"poly", _coords:"1886, 796, 1886, 548, 2260, 548, 2260, 655, 2143, 656, 2097, 701, 2096, 893, 2012, 893, 2013, 795", title:"71"},
                        {shape:"poly", _coords:"1366, 529, 1367, 453, 1519, 453, 1517, 528, 1496, 528, 1496, 549, 1517, 548, 1517, 911, 1560, 911, 1446, 911, 1446, 893, 1436, 893, 1437, 858, 1445, 859, 1447, 637, 1357, 636, 1359, 549, 1387, 549, 1387, 529", title:"39"},
                        {shape:"rect", _coords:"882, 798, 968, 893", title:"43"},
                        {shape:"poly", _coords:"883, 547, 1008, 547, 1008, 533, 1144, 534, 1142, 643, 968, 643, 968, 791, 883, 791", title:"52"},
                        {shape:"poly", _coords:"718, 674, 860, 674, 860, 892, 764, 892, 764, 737, 718, 737", title:"45"},
                        {shape:"poly", _coords:"610, 675, 709, 675, 710, 745, 758, 744, 758, 891, 610, 892", title:"46"},
                        {shape:"poly", _coords:"440, 442, 638, 443, 640, 421, 808, 422, 808, 529, 740, 530, 741, 549, 861, 549, 861, 666, 611, 665, 610, 550, 618, 549, 617, 528, 442, 528", title:"44"},
                        {shape:"poly", _coords:"1646, 529, 1646, 437, 1693, 391, 1690, 183, 1884, 183, 1884, 528", title:"59"},
                        {shape:"poly", _coords:"1527, 183, 1686, 183, 1687, 387, 1640, 435, 1526, 434", title:"55"},
                        {shape:"poly", _coords:"1145, 181, 1361, 183, 1361, 426, 1215, 426, 1143, 327", title:"54"},
                        {shape:"poly", _coords:"989, 183, 1137, 182, 1137, 331, 1211, 432, 1360, 431, 1360, 528, 990, 529", title:"53"},
                        {shape:"poly", _coords:"815, 181, 968, 181, 968, 528, 960, 529, 960, 543, 844, 543, 843, 527, 814, 527", title:"51"},
                        {shape:"poly", _coords:"227, 182, 420, 182, 421, 529, 377, 529, 376, 543, 258, 543, 259, 527, 228, 527", title:"48"},
                        {shape:"rect", _coords:"882, 2208, 971, 2297", title:"27"},
                        {shape:"rect", _coords:"1718, 2421, 1820, 2551", title:"9"},
                        {shape:"rect", _coords:"1841, 2408, 1903, 2551", title:"8"},
                        {shape:"rect", _coords:"2443, 2752, 2624, 2918", title:"14"},
                        {shape:"rect", _coords:"2275, 2671, 2436, 2919", title:"15"},
                        {shape:"rect", _coords:"2092, 2670, 2266, 2917", title:"16"},
                        {shape:"rect", _coords:"1904, 2671, 2085, 2918", title:"17"},
                        {shape:"rect", _coords:"1704, 2671, 1884, 2917", title:"18"},
                        {shape:"rect", _coords:"1519, 2671, 1698, 2918", title:"19"},
                        {shape:"rect", _coords:"2283, 2206, 2624, 2551", title:"13"},
                        {shape:"rect", _coords:"1568, 911, 1791, 1091", title:"75"},
                        {shape:"rect", _coords:"988, 2672, 1331, 2917", title:"22"},
                        {shape:"rect", _coords:"770, 2672, 970, 2918", title:"21"},
                        {shape:"rect", _coords:"411, 2572, 762, 2917", title:"23"},
                        {shape:"rect", _coords:"226, 2571, 402, 2917", title:"25"},
                        {shape:"rect", _coords:"974, 650, 1141, 892", title:"42"},
                        {shape:"rect", _coords:"227, 548, 591, 892", title:"47"},
                        {shape:"rect", _coords:"1163, 549, 1340, 893", title:"41"},
                        {shape:"rect", _coords:"1358, 645, 1440, 853", title:"40"},
                        {shape:"rect", _coords:"1537, 645, 1698, 893", title:"74"},
                        {shape:"rect", _coords:"1718, 631, 1879, 892", title:"73"},
                        {shape:"rect", _coords:"1537, 547, 1699, 637", title:"56"},
                        {shape:"rect", _coords:"1886, 802, 2005, 892", title:"72"},
                        {shape:"rect", _coords:"1527, 442, 1640, 528", title:"58"},
                        {shape:"rect", _coords:"1367, 182, 1518, 445", title:"78"},
                        {shape:"rect", _coords:"638, 182, 808, 414", title:"50"},
                        {shape:"rect", _coords:"441, 182, 630, 435", title:"49"},
                    ],
                    [
                        {shape:"poly", _coords:"1662, 424, 1828, 425, 1829, 510, 1804, 510, 1804, 529, 2206, 529, 2207, 558, 2226, 558, 2226, 531, 2232, 531, 2233, 522, 2325, 523, 2326, 610, 1807, 609, 1673, 520, 1672, 508, 1662, 507", title:"108"},
                        {shape:"poly", _coords:"2227, 748, 2286, 748, 2328, 707, 2569, 707, 2568, 876, 2226, 874", title:"115"},
                        {shape:"poly", _coords:"2036, 617, 2325, 617, 2325, 699, 2282, 742, 2226, 741, 2227, 713, 2206, 714, 2206, 875, 2035, 873", title:"116"},
                        {shape:"poly", _coords:"1852, 873, 2030, 873, 2030, 617, 1887, 618, 1887, 838", title:"117"},
                        {shape:"poly", _coords:"1740, 772, 1741, 874, 1841, 874, 1880, 834, 1880, 616, 1807, 617, 1806, 731, 1786, 772", title:"118"},
                        {shape:"poly", _coords:"1662, 529, 1673, 530, 1800, 613, 1800, 726, 1779, 766, 1740, 766, 1741, 655, 1662, 655", title:"121"},
                        {shape:"poly", _coords:"174, 343, 235, 423, 914, 423, 914, 432, 936, 432, 936, 423, 1099, 423, 1099, 510, 936, 510, 936, 479, 912, 479, 913, 508, 712, 508, 712, 514, 586, 513, 584, 508, 519, 510, 518, 514, 387, 514, 387, 509, 321, 509, 321, 514, 203, 514, 203, 508, 174, 509", title:"99"},
                        {shape:"poly", _coords:"1484, 530, 1527, 529, 1527, 516, 1589, 516, 1589, 530, 1643, 529, 1643, 618, 1483, 618", title:"124"},
                        {shape:"poly", _coords:"1380, 953, 1393, 953, 1393, 913, 1736, 913, 1736, 1096, 1572, 1097, 1573, 1190, 1393, 1190, 1394, 1031, 1379, 1031", title:"128"},
                        {shape:"poly", _coords:"1379, 1302, 1393, 1302, 1394, 1196, 1571, 1197, 1572, 1255, 1736, 1255, 1737, 1413, 1379, 1414", title:"129"},
                        {shape:"poly", _coords:"1393, 1422, 1737, 1422, 1737, 1627, 1394, 1626, 1393, 1606, 1380, 1606, 1379, 1494, 1393, 1494", title:"131"},
                        {shape:"poly", _coords:"1379, 1879, 1394, 1879, 1394, 1818, 1736, 1819, 1736, 1969, 1394, 1968, 1394, 1948, 1379, 1948", title:"133"},
                        {shape:"poly", _coords:"1394, 1974, 1737, 1974, 1737, 2146, 1393, 2146, 1392, 2112, 1379, 2114, 1379, 2027, 1393, 2027", title:"134"},
                        {shape:"poly", _coords:"1320, 2530, 1303, 2530, 1303, 2185, 1459, 2186, 1461, 2530, 1446, 2530, 1446, 2551, 2381, 2551, 2380, 2642, 1455, 2642, 1455, 2895, 1284, 2895, 1284, 2641, 355, 2642, 356, 2550, 1319, 2549", title:"80"},
                        {shape:"poly", _coords:"1304, 529, 1317, 529, 1317, 514, 1442, 515, 1442, 529, 1460, 529, 1460, 702, 1304, 702", title:"90"},
                        {shape:"poly", _coords:"924, 530, 955, 530, 956, 515, 1076, 515, 1075, 530, 1088, 530, 1088, 657, 914, 658, 914, 540, 914, 530", title:"94"},
                        {shape:"poly", _coords:"559, 529, 586, 529, 586, 521, 714, 521, 714, 530, 740, 529, 739, 873, 558, 873", title:"97"},
                        {shape:"poly", _coords:"174, 529, 204, 529, 203, 521, 321, 521, 321, 530, 388, 530, 388, 521, 519, 521, 519, 530, 535, 529, 535, 874, 173, 873", title:"98"},
                        {shape:"poly", _coords:"2332, 523, 2355, 524, 2356, 530, 2394, 529, 2394, 699, 2332, 698", title:"113"},
                        {shape:"poly", _coords:"2403, 528, 2417, 528, 2417, 509, 2543, 510, 2544, 530, 2569, 530, 2568, 700, 2402, 699", title:"114"},
                        {shape:"poly", _coords:"2215, 163, 2569, 163, 2568, 509, 2356, 509, 2356, 518, 2233, 517, 2233, 506, 2216, 506", title:"112"},
                        {shape:"poly", _coords:"2032, 163, 2206, 163, 2207, 507, 2184, 507, 2184, 524, 2061, 524, 2061, 508, 2033, 508", title:"111"},
                        {shape:"poly", _coords:"173, 162, 384, 162, 383, 414, 237, 414, 172, 329", title:"100"},
                        {shape:"poly", _coords:"1012, 2421, 1087, 2420, 1087, 2452, 1101, 2452, 1102, 2531, 1012, 2531", title:"157"},
                        {shape:"poly", _coords:"1463, 2651, 1828, 2651, 1828, 2832, 1842, 2832, 1842, 2897, 1461, 2896", title:"144"},
                        {shape:"poly", _coords:"738, 2650, 913, 2650, 914, 2812, 928, 2813, 928, 2872, 912, 2872, 913, 2897, 738, 2896", title:"146"},
                        {shape:"poly", _coords:"2228, 2187, 2568, 2187, 2568, 2530, 2356, 2531, 2356, 2544, 2226, 2545", title:"139_3"},
                        {shape:"poly", _coords:"1662, 2340, 1886, 2340, 1886, 2530, 1804, 2529, 1804, 2544, 1682, 2544, 1682, 2530, 1662, 2530", title:"137"},
                        {shape:"poly", _coords:"1482, 2332, 1642, 2332, 1642, 2531, 1606, 2531, 1606, 2545, 1544, 2545, 1544, 2529, 1483, 2529", title:"135"},
                        {shape:"poly", _coords:"557, 2187, 734, 2187, 736, 2531, 722, 2530, 722, 2544, 584, 2544, 585, 2531, 556, 2530", title:"151"},
                        {shape:"poly", _coords:"357, 2188, 536, 2188, 537, 2545, 393, 2545, 393, 2530, 357, 2530", title:"150"},
                        {shape:"rect", _coords:"745, 528, 913, 873", title:"95"},
                        {shape:"rect", _coords:"1105, 424, 1282, 509", title:"92"},

                        {shape:"rect", _coords:"1, 4, 1, 5", title:"93"},

                        {shape:"rect", _coords:"1290, 424, 1653, 509", title:"105"},
                        {shape:"rect", _coords:"1303, 2147, 1374, 2186", title:"81_2"},
                        {shape:"rect", _coords:"1273, 913, 1374, 2147", title:"81"},
                        {shape:"rect", _coords:"1304, 872, 1373, 912", title:"89_81"},
                        {shape:"rect", _coords:"1302, 709, 1460, 874", title:"89"},
                        {shape:"rect", _coords:"2206, 2275, 2226, 2411", title:"139_2"},
                        {shape:"rect", _coords:"1894, 2187, 2206, 2529", title:"139_1"},
                        {shape:"rect", _coords:"1662, 2186, 1886, 2334", title:"138"},
                        {shape:"rect", _coords:"192, 2529, 329, 2550", title:"149_2"},
                        {shape:"rect", _coords:"174, 2185, 350, 2531", title:"149_1"},
                        {shape:"rect", _coords:"172, 2550, 349, 2896", title:"149"},
                        {shape:"rect", _coords:"2389, 2548, 2569, 2894", title:"140"},
                        {shape:"rect", _coords:"2219, 2650, 2380, 2897", title:"141"},
                        {shape:"rect", _coords:"2037, 2651, 2212, 2897", title:"142"},
                        {shape:"rect", _coords:"1851, 2649, 2031, 2897", title:"143"},
                        {shape:"rect", _coords:"936, 2650, 1276, 2896", title:"145"},
                        {shape:"rect", _coords:"553, 2649, 730, 2897", title:"147"},
                        {shape:"rect", _coords:"356, 2649, 546, 2896", title:"148"},
                        {shape:"rect", _coords:"742, 2186, 916, 2530", title:"152"},
                        {shape:"rect", _coords:"923, 2318, 1005, 2530", title:"153"},
                        {shape:"rect", _coords:"924, 2187, 986, 2310", title:"154"},
                        {shape:"rect", _coords:"990, 2186, 1088, 2243", title:"155"},
                        {shape:"rect", _coords:"990, 2250, 1087, 2311", title:"156"},
                        {shape:"rect", _coords:"1011, 2318, 1086, 2412", title:"158"},
                        {shape:"rect", _coords:"1109, 2185, 1284, 2531", title:"79"},
                        {shape:"rect", _coords:"1484, 2186, 1643, 2323", title:"136"},
                        {shape:"rect", _coords:"1020, 1990, 1267, 2147", title:"82"},
                        {shape:"rect", _coords:"1021, 1807, 1268, 1985", title:"83"},
                        {shape:"rect", _coords:"1020, 1623, 1266, 1800", title:"84"},
                        {shape:"rect", _coords:"1394, 1635, 1737, 1809", title:"132"},
                        {shape:"rect", _coords:"1020, 1438, 1269, 1616", title:"85"},
                        {shape:"rect", _coords:"1020, 1254, 1267, 1431", title:"86"},
                        {shape:"rect", _coords:"1578, 1104, 1737, 1246", title:"130"},
                        {shape:"rect", _coords:"1022, 1071, 1267, 1248", title:"87"},
                        {shape:"rect", _coords:"1021, 912, 1267, 1065", title:"88"},
                        {shape:"rect", _coords:"1662, 824, 1733, 873", title:"119"},
                        {shape:"rect", _coords:"1662, 771, 1733, 819", title:"120"},
                        {shape:"rect", _coords:"1662, 717, 1733, 765", title:"122"},
                        {shape:"rect", _coords:"1662, 664, 1734, 711", title:"123"},
                        {shape:"rect", _coords:"1484, 793, 1642, 895", title:"127"},
                        {shape:"rect", _coords:"1564, 625, 1642, 787", title:"125"},
                        {shape:"rect", _coords:"1484, 625, 1558, 787", title:"126"},
                        {shape:"rect", _coords:"1110, 528, 1284, 873", title:"91"},
                        {shape:"rect", _coords:"922, 666, 1087, 874", title:"96"},
                        {shape:"rect", _coords:"1850, 164, 2026, 509", title:"110"},
                        {shape:"rect", _coords:"1660, 163, 1829, 415", title:"109"},
                        {shape:"rect", _coords:"1470, 163, 1654, 416", title:"107"},
                        {shape:"rect", _coords:"1290, 163, 1464, 416", title:"106"},
                        {shape:"rect", _coords:"1105, 163, 1282, 415", title:"104"},
                        {shape:"rect", _coords:"935, 163, 1099, 415", title:"103"},
                        {shape:"rect", _coords:"757, 163, 913, 415", title:"102"},
                        {shape:"rect", _coords:"390, 163, 749, 412", title:"101"},
                    ],
                    [
                        {shape:"rect", _coords:"1665, 787, 1759, 850",title:"190"},
                        {shape:"rect", _coords:"1002, 2232, 1097, 2290",title:"221"},
                        {shape:"rect", _coords:"931, 141, 1284, 375",title:"179"},
                        {shape:"rect", _coords:"1292, 142, 1461, 377",title:"181"},
                        {shape:"rect", _coords:"1467, 143, 1840, 378",title:"183"},
                        {shape:"rect", _coords:"1031, 1051, 1276, 1409",title:"166"},
                        {shape:"rect", _coords:"1031, 1422, 1275, 1595",title:"165"},
                        {shape:"rect", _coords:"1404, 873, 1745, 1394",title:"198"},
                        {shape:"rect", _coords:"1405, 1404, 1743, 1605",title:"199"},
                        {shape:"rect", _coords:"1405, 1615, 1743, 2146",title:"200"},
                        {shape:"rect", _coords:"1032, 1602, 1275, 1962",title:"164"},
                        {shape:"rect", _coords:"1034, 1973, 1274, 2145",title:"163"},
                        {shape:"rect", _coords:"1471, 2274, 1651, 2386",title:"201"},
                        {shape:"rect", _coords:"1470, 2168, 1650, 2264",title:"207"},
                        {shape:"rect", _coords:"1675, 2167, 1896, 2402",title:"206"},
                        {shape:"rect", _coords:"368, 2169, 926, 2509",title:"217"},
                        {shape:"rect", _coords:"564, 2657, 936, 2872",title:"214"},
                        {shape:"rect", _coords:"934, 2167, 994, 2290",title:"219"},
                        {shape:"rect", _coords:"1002, 2168, 1097, 2218",title:"220"},
                        {shape:"rect", _coords:"1119, 2167, 1291, 2508",title:"159"},
                        {shape:"rect", _coords:"934, 2300, 1014, 2509",title:"218"},
                        {shape:"rect", _coords:"1025, 2298, 1095, 2391",title:"223"},
                        {shape:"rect", _coords:"1315, 2170, 1461, 2507",title:"161"},
                        {shape:"rect", _coords:"1469, 2395, 1651, 2507",title:"202"},
                        {shape:"rect", _coords:"1675, 2412, 1897, 2506",title:"204"},
                        {shape:"rect", _coords:"1906, 2168, 2214, 2507",title:"205"},
                        {shape:"rect", _coords:"2226, 2167, 2576, 2507",title:"208"},
                        {shape:"rect", _coords:"1850, 141, 2220, 377",title:"184"},
                        {shape:"rect", _coords:"2230, 142, 2578, 479",title:"185"},
                        {shape:"rect", _coords:"1464, 749, 1643, 851",title:"197"},
                        {shape:"rect", _coords:"1464, 580, 1545, 738",title:"196"},
                        {shape:"rect", _coords:"1554, 579, 1640, 740",title:"195"},
                        {shape:"rect", _coords:"1663, 501, 1755, 602",title:"192"},
                        {shape:"rect", _coords:"1665, 614, 1725, 703",title:"193"},
                        {shape:"rect", _coords:"1665, 714, 1758, 777",title:"191"},
                        {shape:"rect", _coords:"1770, 714, 1865, 850",title:"189"},
                        {shape:"rect", _coords:"183, 141, 528, 480",title:"177"},
                        {shape:"rect", _coords:"537, 142, 922, 376",title:"178"},
                        {shape:"rect", _coords:"1030, 874, 1274, 1043",title:"167"},
                        {shape:"rect", _coords:"1469, 388, 2221, 479",title:"182"},
                        {shape:"poly", _coords:"182, 2510, 182, 2166, 357, 2166, 357, 2509, 330, 2508, 330, 2520, 281, 2571, 222, 2515, 208, 2515, 208, 2508",title:"216"},
                        {shape:"poly", _coords:"184, 2530, 211, 2531, 212, 2522, 223, 2522, 333, 2633, 359, 2633, 359, 2876, 184, 2875",title:"215"},
                        {shape:"rect", _coords:"1022, 2399, 1110, 2509",title:"222"},
                        {shape:"poly", _coords:"183, 501, 209, 501, 210, 486, 325, 487, 325, 501, 395, 500, 395, 487, 516, 487, 516, 501, 522, 502, 524, 849, 182, 852",title:"175"},
                        {shape:"rect", _coords:"536, 385, 1280, 479",title:"171"},
                        {shape:"rect", _coords:"557, 479, 661, 501",title:"171_173"},
                        {shape:"rect", _coords:"530, 501, 817, 640",title:"173"},
                        {shape:"rect", _coords:"1115, 501, 1279, 847",title:"170"},
                        {shape:"rect", _coords:"1299, 660, 1454, 848",title:"168"},
                        {shape:"rect", _coords:"1290, 386, 1462, 478",title:"180"},
                        {shape:"rect", _coords:"1325, 478, 1431, 501",title:"180_169"},
                        {shape:"rect", _coords:"1298, 501, 1455, 650",title:"169"},
                        {shape:"poly", _coords:"530, 850, 531, 646, 636, 646, 639, 789, 592, 849",title:"176"},
                        {shape:"poly", _coords:"603, 849, 646, 791, 644, 648, 763, 647, 763, 850",title:"174"},
                        {shape:"poly", _coords:"772, 849, 771, 647, 825, 647, 826, 501, 943, 502, 942, 487, 1058, 487, 1058, 502, 1092, 502, 1092, 850",title:"172"},
                        {shape:"poly", _coords:"1462, 502, 1512, 502, 1514, 488, 1632, 487, 1634, 502, 1643, 501, 1643, 570, 1462, 569",title:"194"},
                        {shape:"poly", _coords:"1735, 612, 1765, 613, 1762, 501, 1865, 501, 1866, 705, 1734, 706",title:"188"},
                        {shape:"poly", _coords:"1874, 850, 1874, 500, 1888, 501, 1889, 486, 2023, 488, 2024, 499, 2047, 501, 2047, 847",title:"187"},
                        {shape:"poly", _coords:"2055, 850, 2054, 501, 2084, 501, 2085, 487, 2208, 488, 2208, 503, 2270, 504, 2270, 488, 2377, 486, 2377, 501, 2440, 500, 2441, 485, 2557, 487, 2556, 502, 2578, 502, 2579, 849",title:"186"},
                        {shape:"poly", _coords:"1283, 871, 1302, 869, 1298, 848, 1379, 849, 1381, 2146, 1283, 2145",title:"162"},
                        {shape:"poly", _coords:"286, 2578, 329, 2531, 403, 2529, 402, 2516, 531, 2516, 531, 2531, 555, 2529, 556, 2876, 368, 2876, 367, 2624, 334, 2623",title:"213"},
                        {shape:"poly", _coords:"564, 2530, 603, 2530, 603, 2516, 716, 2517, 716, 2530, 788, 2530, 786, 2517, 896, 2516, 897, 2530, 1011, 2530, 1013, 2646, 563, 2647",title:"211"},
                        {shape:"poly", _coords:"943, 2653, 1054, 2654, 1112, 2711, 1110, 2875, 944, 2875",title:"212"},
                        {shape:"poly", _coords:"1021, 2530, 1492, 2531, 1493, 2516, 1565, 2516, 1566, 2646, 1524, 2645, 1466, 2708, 1466, 2874, 1121, 2874, 1122, 2707, 1059, 2646, 1022, 2646",title:"160"},
                        {shape:"poly", _coords:"1475, 2876, 1474, 2708, 1530, 2653, 1788, 2653, 1844, 2709, 1845, 2876",title:"210"},
                        {shape:"poly", _coords:"1574, 2516, 1616, 2517, 1616, 2530, 1682, 2529, 1682, 2515, 1814, 2515, 1814, 2531, 2086, 2530, 2086, 2516, 2208, 2515, 2208, 2530, 2222, 2531, 2222, 2877, 1854, 2874, 1854, 2706, 1790, 2645, 1573, 2648",title:"203"},
                        {shape:"poly", _coords:"2231, 2529, 2270, 2529, 2270, 2515, 2374, 2516, 2375, 2530, 2437, 2529, 2437, 2514, 2548, 2516, 2548, 2530, 2579, 2530, 2580, 2876, 2229, 2874",title:"209"},
                    ],
                    [
                        {shape:"poly", _coords:"2168, 2526, 2393, 2525, 2393, 2872, 2228, 2873, 2227, 2640, 2166, 2640", title:"233"},
                        {shape:"poly", _coords:"2228, 2163, 2572, 2163, 2572, 2505, 2504, 2505, 2504, 2253, 2228, 2253", title:"231"},
                        {shape:"poly", _coords:"1666, 2389, 1720, 2388, 1720, 2184, 1907, 2184, 1907, 2162, 1664, 2163", title:"227"},
                        {shape:"rect", _coords:"1485, 2419, 1541, 2505", title:"225"},
                        {shape:"poly", _coords:"1484, 2163, 1644, 2163, 1645, 2505, 1549, 2505, 1549, 2412, 1485, 2411", title:"224"},
                        {shape:"poly", _coords:"557, 2505, 616, 2504, 616, 2231, 966, 2231, 968, 2391, 1089, 2390, 1090, 2163, 557, 2163", title:"244"},
                        {shape:"poly", _coords:"1022, 1617, 1379, 1617, 1380, 1584, 1508, 1584, 1508, 1725, 1378, 1726, 1378, 1677, 1022, 1678", title:"250"},
                        {shape:"poly", _coords:"1124, 1485, 1220, 1484, 1221, 1412, 1358, 1413, 1359, 1608, 1124, 1609", title:"251"},
                        {shape:"poly", _coords:"1021, 1413, 1214, 1412, 1214, 1477, 1117, 1478, 1117, 1608, 1021, 1608", title:"249"},
                        {shape:"poly", _coords:"1379, 866, 1738, 866, 1739, 1422, 1508, 1423, 1508, 1576, 1380, 1576", title:"274"},
                        {shape:"rect", _coords:"1521, 845, 1639, 865", title:"273_274"},
                        {shape:"rect", _coords:"1471, 502, 1639, 845", title:"273"},
                        {shape:"rect", _coords:"1022, 866, 1358, 1405", title:"275"},
                        {shape:"rect", _coords:"1358, 1126, 1378, 1179", title:"275_274"},
                        {shape:"poly", _coords:"2517, 503, 2572, 503, 2572, 845, 2219, 845, 2219, 797, 2516, 797", title:"272"},
                        {shape:"poly", _coords:"2246, 510, 2508, 510, 2510, 791, 2225, 793, 2225, 613, 2246, 613", title:"271"},
                        {shape:"poly", _coords:"1657, 505, 1901, 505, 1901, 550, 1709, 550, 1709, 801, 2197, 801, 2198, 846, 1657, 846", title:"269"},
                        {shape:"poly", _coords:"1907, 509, 2176, 509, 2177, 793, 1717, 793, 1717, 555, 1907, 554", title:"270"},
                        {shape:"poly", _coords:"922, 368, 1838, 368, 1838, 482, 922, 483", title:"260"},
                        {shape:"poly", _coords:"175, 350, 385, 349, 385, 135, 490, 135, 489, 368, 922, 368, 922, 483, 174, 481", title:"261"},
                        {shape:"rect", _coords:"1845, 135, 2573, 481", title:"1N4"},
                        {shape:"poly", _coords:"934, 135, 1838, 135, 1838, 232, 1738, 232, 1740, 360, 934, 359", title:"267"},
                        {shape:"poly", _coords:"569, 503, 971, 503, 971, 604, 1093, 604, 1093, 845, 570, 845", title:"262"},
                        {shape:"poly", _coords:"175, 502, 548, 503, 549, 846, 174, 845, 235, 792, 515, 793, 513, 538, 235, 539, 235, 792, 176, 844", title:"263"},
                        {shape:"rect", _coords:"1666, 2394, 2208, 2504", title:"226"},
                        {shape:"rect", _coords:"1306, 2162, 1463, 2309", title:"248"},
                        {shape:"rect", _coords:"2228, 2261, 2499, 2504", title:"234"},
                        {shape:"rect", _coords:"2054, 2163, 2208, 2388", title:"230"},
                        {shape:"rect", _coords:"1913, 2163, 2047, 2389", title:"229"},
                        {shape:"rect", _coords:"1729, 2192, 1907, 2389", title:"228"},
                        {shape:"rect", _coords:"2414, 2526, 2572, 2873", title:"235"},
                        {shape:"rect", _coords:"1843, 2646, 2221, 2833", title:"236"},
                        {shape:"rect", _coords:"1874, 2527, 2161, 2640", title:"232"},
                        {shape:"rect", _coords:"942, 2533, 1813, 2824", title:"237"},
                        {shape:"rect", _coords:"175, 2647, 915, 2873", title:"239"},
                        {shape:"rect", _coords:"176, 2527, 884, 2639", title:"238"},
                        {shape:"rect", _coords:"1111, 2162, 1287, 2311", title:"246"},
                        {shape:"rect", _coords:"1308, 2319, 1463, 2505", title:"247"},
                        {shape:"rect", _coords:"1112, 2319, 1286, 2505", title:"245"},
                        {shape:"rect", _coords:"974, 2397, 1084, 2505", title:"241"},
                        {shape:"rect", _coords:"626, 2239, 955, 2505", title:"242"},
                        {shape:"rect", _coords:"175, 2162, 536, 2505", title:"240"},
                        {shape:"rect", _coords:"1021, 1686, 1200, 2142", title:"256"},
                        {shape:"rect", _coords:"1206, 1951, 1373, 2142", title:"257"},
                        {shape:"rect", _coords:"1205, 1684, 1358, 1945", title:"255"},
                        {shape:"rect", _coords:"1517, 1654, 1736, 2142", title:"253"},
                        {shape:"rect", _coords:"1379, 1735, 1508, 2141", title:"254"},
                        {shape:"rect", _coords:"1516, 1429, 1737, 1647", title:"252"},
                        {shape:"rect", _coords:"1297, 502, 1449, 844", title:"276"},
                        {shape:"rect", _coords:"1115, 503, 1277, 846", title:"258"},
                        {shape:"rect", _coords:"979, 503, 1093, 597", title:"259"},
                        {shape:"rect", _coords:"243, 545, 506, 784", title:"264"},
                        {shape:"rect", _coords:"1746, 241, 1838, 359", title:"268"},
                        {shape:"rect", _coords:"496, 134, 913, 360", title:"266"},
                        {shape:"rect", _coords:"172, 133, 377, 342", title:"265"},
                    ],
                    [
                        {shape:"rect", _coords:"2576, 1850, 2719, 1924", title:"P1"},
                        {shape:"rect", _coords:"2576, 1769, 2719, 1843", title:"P2"},
                        {shape:"rect", _coords:"2576, 1687, 2719, 1761", title:"P3"},
                        {shape:"rect", _coords:"2576, 1606, 2719, 1680", title:"P4"},
                        {shape:"rect", _coords:"2576, 1525, 2719, 1599", title:"P5"},
                        {shape:"rect", _coords:"2576, 1443, 2719, 1517", title:"P6"},
                        {shape:"rect", _coords:"2576, 1362, 2719, 1436", title:"P7"},
                        {shape:"rect", _coords:"2576, 1280, 2719, 1354", title:"P8"},
                        {shape:"rect", _coords:"2576, 1199, 2719, 1273", title:"P9"},
                        {shape:"rect", _coords:"2576, 1118, 2719, 1192", title:"P10"},
                        {shape:"rect", _coords:"2576, 1036, 2719, 1110", title:"P11"},
                        {shape:"rect", _coords:"2576, 954, 2719, 1028", title:"P12"},
                        {shape:"rect", _coords:"2576, 873, 2719, 947", title:"P13"},
                        {shape:"rect", _coords:"2576, 791, 2719, 865", title:"P14"},
                        {shape:"rect", _coords:"2576, 710, 2719, 784", title:"P15"},
                        {shape:"rect", _coords:"2576, 629, 2719, 703", title:"P16"},
                        {shape:"rect", _coords:"2408, 479, 2483, 606", title:"P17"},
                        {shape:"rect", _coords:"2327, 479, 2402, 606", title:"P18"},
                        {shape:"rect", _coords:"2246, 479, 2321, 606", title:"P19"},
                        {shape:"rect", _coords:"2165, 479, 2240, 606", title:"P20"},
                        {shape:"rect", _coords:"2084, 479, 2159, 606", title:"P21"},
                        {shape:"rect", _coords:"2002, 479, 2077, 606", title:"P22"},
                        {shape:"rect", _coords:"1921, 479, 1996, 606", title:"P23"},
                        {shape:"rect", _coords:"1840, 479, 1915, 606", title:"P24"},
                        {shape:"rect", _coords:"1759, 479, 1834, 606", title:"P25"},
                        {shape:"rect", _coords:"1678, 479, 1753, 606", title:"P26"},
                        {shape:"rect", _coords:"1596, 479, 1671, 606", title:"P27"},
                        {shape:"rect", _coords:"1515, 479, 1590, 606", title:"P28"},
                        {shape:"rect", _coords:"1354, 479, 1429, 606", title:"P29"},
                        {shape:"rect", _coords:"1272, 479, 1347, 606", title:"P30"},
                        {shape:"rect", _coords:"1191, 479, 1266, 606", title:"P31"},
                        {shape:"rect", _coords:"1110, 479, 1185, 606", title:"P32"},
                        {shape:"rect", _coords:"1029, 479, 1104, 606", title:"P33"},
                        {shape:"rect", _coords:"947, 479, 1022, 606", title:"P34"},
                        {shape:"rect", _coords:"866, 479, 941, 606", title:"P35"},
                        {shape:"rect", _coords:"785, 479, 860, 606", title:"P36"},
                        {shape:"rect", _coords:"704, 479, 779, 606", title:"P37"},
                        {shape:"rect", _coords:"622, 479, 697, 606", title:"P38"},
                        {shape:"rect", _coords:"542, 479, 617, 606", title:"P39"},
                        {shape:"rect", _coords:"461, 479, 536, 606", title:"P40"},
                        {shape:"rect", _coords:"380, 479, 455, 606", title:"P41"},
                        {shape:"rect", _coords:"299, 479, 374, 606", title:"P42"},
                        {shape:"rect", _coords:"51, 629, 194, 703", title:"P43"},
                        {shape:"rect", _coords:"51, 711, 194, 785", title:"P44"},
                        {shape:"rect", _coords:"51, 792, 194, 866", title:"P45"},
                        {shape:"rect", _coords:"51, 873, 194, 947", title:"P46"},
                        {shape:"rect", _coords:"51, 955, 194, 1029", title:"P47"},
                        {shape:"rect", _coords:"51, 1036, 194, 1110", title:"P48"},
                        {shape:"rect", _coords:"51, 1117, 194, 1191", title:"P49"},
                        {shape:"rect", _coords:"51, 1199, 194, 1273", title:"P50"},
                        {shape:"rect", _coords:"51, 1280, 194, 1354", title:"P51"},
                        {shape:"rect", _coords:"51, 1362, 194, 1436", title:"P52"},
                        {shape:"rect", _coords:"51, 1443, 194, 1517", title:"P53"},
                        {shape:"rect", _coords:"51, 1525, 194, 1599", title:"P54"},
                        {shape:"rect", _coords:"51, 1606, 194, 1680", title:"P55"},
                        {shape:"rect", _coords:"51, 1688, 194, 1762", title:"P56"},
                        {shape:"rect", _coords:"51, 1769, 194, 1843", title:"P57"},
                        {shape:"rect", _coords:"51, 1850, 194, 1924", title:"P58"},
                        {shape:"rect", _coords:"51, 1932, 194, 2006", title:"P59"},
                        {shape:"rect", _coords:"51, 2013, 194, 2087", title:"P60"},
                        {shape:"rect", _coords:"51, 2095, 194, 2169", title:"P61"},
                        {shape:"rect", _coords:"51, 2176, 194, 2250", title:"P62"},
                        {shape:"rect", _coords:"51, 2257, 194, 2331", title:"P63"},
                        {shape:"rect", _coords:"51, 2339, 194, 2413", title:"P64"},
                        {shape:"rect", _coords:"359, 2409, 502, 2483", title:"P65"},
                        {shape:"rect", _coords:"359, 2327, 502, 2401", title:"P66"},
                        {shape:"rect", _coords:"359, 2246, 502, 2320", title:"P67"},
                        {shape:"rect", _coords:"359, 2164, 502, 2238", title:"P68"},
                        {shape:"rect", _coords:"359, 2083, 502, 2157", title:"P69"},
                        {shape:"rect", _coords:"359, 2002, 502, 2076", title:"P70"},
                        {shape:"rect", _coords:"359, 1920, 502, 1994", title:"P71"},
                        {shape:"rect", _coords:"514, 1922, 589, 2049", title:"P72"},
                        {shape:"rect", _coords:"595, 1922, 670, 2049", title:"P73"},
                        {shape:"rect", _coords:"677, 1922, 752, 2049", title:"P74"},
                        {shape:"rect", _coords:"758, 1922, 833, 2049", title:"P75"},
                        {shape:"rect", _coords:"757, 1395, 832, 1522", title:"P76"},
                        {shape:"rect", _coords:"677, 1395, 752, 1522", title:"P77"},
                        {shape:"rect", _coords:"596, 1395, 671, 1522", title:"P78"},
                        {shape:"rect", _coords:"515, 1395, 590, 1522", title:"P79"},
                        {shape:"rect", _coords:"359, 1481, 502, 1555", title:"P80"},
                        {shape:"rect", _coords:"359, 1399, 502, 1473", title:"P81"},
                        {shape:"rect", _coords:"359, 1318, 502, 1392", title:"P82"},
                        {shape:"rect", _coords:"359, 1237, 502, 1311", title:"P83"},
                        {shape:"rect", _coords:"359, 1155, 502, 1229", title:"P84"},
                        {shape:"rect", _coords:"359, 1074, 502, 1148", title:"P85"},
                        {shape:"rect", _coords:"359, 992, 502, 1066", title:"P86"},
                        {shape:"rect", _coords:"359, 911, 502, 985", title:"P87"},
                        {shape:"rect", _coords:"359, 829, 502, 903", title:"P88"},
                        {shape:"rect", _coords:"652, 817, 820, 890", title:"P89"},
                        {shape:"rect", _coords:"829, 817, 997, 890", title:"P90"},
                        {shape:"rect", _coords:"1007, 817, 1175, 890", title:"P91"},
                        {shape:"rect", _coords:"1185, 817, 1353, 890", title:"P92"},
                        {shape:"rect", _coords:"1362, 817, 1530, 890", title:"P93"},
                        {shape:"rect", _coords:"1539, 817, 1707, 890", title:"P94"},
                        {shape:"rect", _coords:"1717, 817, 1885, 890", title:"P95"},
                        {shape:"rect", _coords:"2062, 875, 2205, 949", title:"P96"},
                        {shape:"rect", _coords:"2062, 957, 2205, 1031", title:"P97"},
                        {shape:"rect", _coords:"2062, 1037, 2205, 1111", title:"P98"},
                        {shape:"rect", _coords:"2062, 1119, 2205, 1193", title:"P99"},
                        {shape:"rect", _coords:"2062, 1200, 2205, 1274", title:"P100"},
                        {shape:"rect", _coords:"2062, 1281, 2205, 1355", title:"P101"},
                        {shape:"rect", _coords:"2062, 1363, 2205, 1437", title:"P102"},
                        {shape:"poly", _coords:"2060, 2073, 2205, 1946, 2205, 2045, 2060, 2172", title:"P103"},
                        {shape:"poly", _coords:"2060, 2184, 2205, 2057, 2205, 2156, 2060, 2283", title:"P104"},
                        {shape:"poly", _coords:"2060, 2293, 2205, 2166, 2205, 2265, 2060, 2392", title:"P105"},
                        {shape:"poly", _coords:"2060, 2402, 2205, 2275, 2205, 2374, 2060, 2501", title:"P106"},
                        {shape:"poly", _coords:"2067, 2505, 2212, 2378, 2328, 2378, 2183, 2505", title:"P107"},
                        {shape:"poly", _coords:"2193, 2505, 2338, 2378, 2454, 2378, 2309, 2505", title:"P108"},
                        {shape:"poly", _coords:"2321, 2505, 2466, 2378, 2582, 2378, 2437, 2505", title:"P109"},
                        {shape:"poly", _coords:"2446, 2505, 2591, 2378, 2707, 2378, 2562, 2505", title:"P110"},
                    ],
                ],
                squares: {
                    "1N4":{"square":"67.0 кв.м."},
                    "224":{"square":"10.8 кв.м."},
                    "225":{"square":"1.4 кв.м."},
                    "226":{"square":"15.4 кв.м."},
                    "227":{"square":"5.4 кв.м."},
                    "228":{"square":"8.4 кв.м."},
                    "229":{"square":"8.3 кв.м."},
                    "230":{"square":"9.8 кв.м."},
                    "231":{"square":"13.3 кв.м."},
                    "232":{"square":"8.1 кв.м."},
                    "233":{"square":"20.2 кв.м."},
                    "234":{"square":"18.8 кв.м."},
                    "235":{"square":"13.7 кв.м."},
                    "236":{"square":"17.2 кв.м."},
                    "237":{"square":"63.9 кв.м."},
                    "238":{"square":"21.4 кв.м."},
                    "239":{"square":"43.8 кв.м."},
                    "240":{"square":"33.2 кв.м."},
                    "241":{"square":"1.7 кв.м."},
                    "242":{"square":"22.3 кв.м."},
                    "244":{"square":"17.9 кв.м."},
                    "245":{"square":"7.8 кв.м."},
                    "246":{"square":"6.8 кв.м."},
                    "247":{"square":"7.6 кв.м."},
                    "248":{"square":"5.2 кв.м."},
                    "249":{"square":"5.7 кв.м."},
                    "250":{"square":"11.6 кв.м."},
                    "251":{"square":"9.7 кв.м."},
                    "252":{"square":"11.2 кв.м."},
                    "253":{"square":"24.7 кв.м."},
                    "254":{"square":"15.1 кв.м."},
                    "255":{"square":"10.7 кв.м."},
                    "256":{"square":"21.3 кв.м."},
                    "257":{"square":"7.6 кв.м."},
                    "258":{"square":"14.9 кв.м."},
                    "259":{"square":"3.0 кв.м."},
                    "260":{"square":"26.5 кв.м."},
                    "261":{"square":"27.8 кв.м."},
                    "262":{"square":"45.8 кв.м."},
                    "263":{"square":"15.7 кв.м."},
                    "264":{"square":"16.4 кв.м."},
                    "265":{"square":"11.4 кв.м."},
                    "266":{"square":"23.7 кв.м."},
                    "267":{"square":"50.2 кв.м."},
                    "268":{"square":"2.6 кв.м."},
                    "269":{"square":"12.6 кв.м."},
                    "270":{"square":"32.9 кв.м."},
                    "271":{"square":"21.3 кв.м."},
                    "272":{"square":"8.2 кв.м."},
                    "273":{"square":"15.4 кв.м."},
                    "273_274":{"square":"273_274"},
                    "274":{"square":"57.3 кв.м."},
                    "275":{"square":"50.1 кв.м."},
                    "275_274":{"square":"275_274"},
                    "276":{"square":"13.8 кв.м."},
                    "1":{"square":"4.2 кв.м."},
                    "2":{"square":"68.9 кв.м."},
                    "3":{"square":"19.0 кв.м."},
                    "4":{"square":"50.6 кв.м."},
                    "5":{"square":"9.1 кв.м."},
                    "6":{"square":"5.4 кв.м."},
                    "7":{"square":"22.6 кв.м."},
                    "8":{"square":"1.8 кв.м."},
                    "9":{"square":"3.1 кв.м."},
                    "10":{"square":"9.2 кв.м."},
                    "11":{"square":"1.7 кв.м."},
                    "12":{"square":"19.4 кв.м."},
                    "13":{"square":"31.7 кв.м."},
                    "14":{"square":"7.9 кв.м."},
                    "15":{"square":"11.3 кв.м."},
                    "16":{"square":"11.4 кв.м."},
                    "17":{"square":"11.1 кв.м."},
                    "18":{"square":"10.4 кв.м."},
                    "19":{"square":"11.7 кв.м."},
                    "20":{"square":"18.6 кв.м."},
                    "21":{"square":"12.1 кв.м."},
                    "22":{"square":"22.6 кв.м."},
                    "23":{"square":"30.5 кв.м."},
                    "24":{"square":"32.1 кв.м."},
                    "25":{"square":"15.9 кв.м."},
                    "26":{"square":"15.0 кв.м."},
                    "27":{"square":"2.0 кв.м."},
                    "28":{"square":"4.9 кв.м."},
                    "29":{"square":"2.3 кв.м."},
                    "30":{"square":"1.4 кв.м."},
                    "31":{"square":"1.5 кв.м."},
                    "32":{"square":"1.9 кв.м."},
                    "33":{"square":"1.6 кв.м."},
                    "34":{"square":"14.6 кв.м."},
                    "35":{"square":"48.0 кв.м."},
                    "36":{"square":"1.7 кв.м."},
                    "37":{"square":"56.0 кв.м."},
                    "38":{"square":"4.9 кв.м."},
                    "39":{"square":"13.3 кв.м."},
                    "40":{"square":"4.5 кв.м."},
                    "41":{"square":"14.7 кв.м."},
                    "42":{"square":"10.2 кв.м."},
                    "43":{"square":"2.2 кв.м."},
                    "44":{"square":"16.8 кв.м."},
                    "45":{"square":"6.5 кв.м."},
                    "46":{"square":"7.2 кв.м."},
                    "47":{"square":"31.4 кв.м."},
                    "48":{"square":"17.9 кв.м."},
                    "49":{"square":"12.3 кв.м."},
                    "50":{"square":"10.5 кв.м."},
                    "51":{"square":"14.9 кв.м."},
                    "52":{"square":"10.8 кв.м."},
                    "53":{"square":"19.2 кв.м."},
                    "54":{"square":"12.8 кв.м."},
                    "55":{"square":"10.4 кв.м."},
                    "56":{"square":"3.4 кв.м."},
                    "57":{"square":"3.5 кв.м."},
                    "58":{"square":"2.8 кв.м."},
                    "59":{"square":"17.8 кв.м."},
                    "60":{"square":"1.3 кв.м."},
                    "61":{"square":"11.8 кв.м."},
                    "62":{"square":"8.5 кв.м."},
                    "63":{"square":"9.2 кв.м."},
                    "64":{"square":"22.7 кв.м."},
                    "65":{"square":"9.9 кв.м."},
                    "66":{"square":"13.9 кв.м."},
                    "67":{"square":"7.8 кв.м."},
                    "68":{"square":"9.8 кв.м."},
                    "69":{"square":"9.1 кв.м."},
                    "70":{"square":"3.0 кв.м."},
                    "71":{"square":"20.3 кв.м."},
                    "72":{"square":"2.8 кв.м."},
                    "73":{"square":"10.8 кв.м."},
                    "74":{"square":"10.5 кв.м."},
                    "75":{"square":"10.0 кв.м."},
                    "76":{"square":"8.5 кв.м."},
                    "77":{"square":"9.0 кв.м."},
                    "78":{"square":"11.1 кв.м."},
                    "79":{"square":"14.6 кв.м."},
                    "80":{"square":"78.6 кв.м."},
                    "81":{"square":"30.1 кв.м."},
                    "81_2":{"square":"81_2"},
                    "82":{"square":"10.4 кв.м."},
                    "83":{"square":"11.4 кв.м."},
                    "84":{"square":"11.6 кв.м."},
                    "85":{"square":"11.8 кв.м."},
                    "86":{"square":"11.4 кв.м."},
                    "87":{"square":"11.5 кв.м."},
                    "88":{"square":"10.5 кв.м."},
                    "89":{"square":"7.6 кв.м."},
                    "89_81":{"square":"89_81"},
                    "90":{"square":"8.3 кв.м."},
                    "91":{"square":"14.7 кв.м."},
                    "92":{"square":"3.8 кв.м."},
                    "93":{"square":"3.4 кв.м."},
                    "94":{"square":"6.4 кв.м."},
                    "95":{"square":"15.3 кв.м."},
                    "96":{"square":"9.9 кв.м."},
                    "97":{"square":"16.2 кв.м."},
                    "98":{"square":"33.7 кв.м."},
                    "99":{"square":"16.9 кв.м."},
                    "100":{"square":"13.6 кв.м."},
                    "101":{"square":"20.9 кв.м."},
                    "102":{"square":"13.3 кв.м."},
                    "103":{"square":"11.5 кв.м."},
                    "104":{"square":"12.1 кв.м."},
                    "105":{"square":"7.7 кв.м."},
                    "106":{"square":"12.1 кв.м."},
                    "107":{"square":"12.5 кв.м."},
                    "108":{"square":"16.3 кв.м."},
                    "109":{"square":"11.5 кв.м."},
                    "110":{"square":"14.7 кв.м."},
                    "111":{"square":"16.7 кв.м."},
                    "112":{"square":"33.5 кв.м."},
                    "113":{"square":"3.0 кв.м."},
                    "114":{"square":"7.7 кв.м."},
                    "115":{"square":"14.9 кв.м."},
                    "116":{"square":"15.6 кв.м."},
                    "117":{"square":"9.9 кв.м."},
                    "118":{"square":"6.4 кв.м."},
                    "119":{"square":"0.9 кв.м."},
                    "120":{"square":"0.9 кв.м."},
                    "121":{"square":"4.4 кв.м."},
                    "122":{"square":"0.8 кв.м."},
                    "123":{"square":"0.9 кв.м."},
                    "124":{"square":"3.6 кв.м."},
                    "125":{"square":"3.3 кв.м."},
                    "126":{"square":"3.2 кв.м."},
                    "127":{"square":"2.58 кв.м."},
                    "128":{"square":"21.4 кв.м."},
                    "129":{"square":"18.2 кв.м."},
                    "130":{"square":"6.0 кв.м."},
                    "131":{"square":"19.7 кв.м."},
                    "132":{"square":"16.2 кв.м."},
                    "133":{"square":"14.2 кв.м."},
                    "134":{"square":"15.2 кв.м."},
                    "135":{"square":"8.7 кв.м."},
                    "136":{"square":"5.6 кв.м."},
                    "137":{"square":"10.6 кв.м."},
                    "138":{"square":"7.8 кв.м."},
                    "139_1":{"square":"62.3 кв.м."},
                    "139_2":{"square":"62.3 кв.м."},
                    "139_3":{"square":"62.3 кв.м."},
                    "140":{"square":"16.2 кв.м."},
                    "141":{"square":"11.3 кв.м."},
                    "142":{"square":"11.3 кв.м."},
                    "143":{"square":"11.0 кв.м."},
                    "144":{"square":"22.3 кв.м."},
                    "145":{"square":"22.1 кв.м."},
                    "146":{"square":"11.4 кв.м."},
                    "147":{"square":"11.4 кв.м."},
                    "148":{"square":"11.3 кв.м."},
                    "149":{"square":"33.7 кв.м."},
                    "149_1":{"square":"33.7 кв.м."},
                    "149_2":{"square":"33.7 кв.м."},
                    "150":{"square":"15.0 кв.м."},
                    "151":{"square":"17.0 кв.м."},
                    "152":{"square":"14.3 кв.м."},
                    "153":{"square":"6.1 кв.м."},
                    "154":{"square":"2.9 кв.м."},
                    "155":{"square":"1.5 кв.м."},
                    "156":{"square":"1.5 кв.м."},
                    "157":{"square":"2.0 кв.м."},
                    "158":{"square":"1.5 кв.м."},
                    "159":{"square":"14.5 кв.м."},
                    "160":{"square":"41.4 кв.м."},
                    "161":{"square":"15.5 кв.м."},
                    "162":{"square":"31.0 кв.м."},
                    "163":{"square":"11.0 кв.м."},
                    "164":{"square":"23.8 кв.м."},
                    "165":{"square":"11.8 кв.м."},
                    "166":{"square":"24.0 кв.м."},
                    "167":{"square":"10.9 кв.м."},
                    "168":{"square":"7.5 кв.м."},
                    "169":{"square":"6.5 кв.м."},
                    "170":{"square":"14.7 кв.м."},
                    "171":{"square":"19.6 кв.м."},
                    "171_173":{"square":"171_173"},
                    "172":{"square":"28.3 кв.м."},
                    "173":{"square":"10.5 кв.м."},
                    "174":{"square":"6.8 кв.м."},
                    "175":{"square":"31.7 кв.м."},
                    "176":{"square":"4.9 кв.м."},
                    "177":{"square":"31.1 кв.м."},
                    "178":{"square":"26.1 кв.м."},
                    "179":{"square":"23.8 кв.м."},
                    "180":{"square":"4.4 кв.м."},
                    "180_169":{"square":"180_169"},
                    "181":{"square":"11.8 кв.м."},
                    "182":{"square":"18.1 кв.м."},
                    "183":{"square":"23.7 кв.м."},
                    "184":{"square":"24.0 кв.м."},
                    "185":{"square":"33.8 кв.м."},
                    "186":{"square":"52.0 кв.м."},
                    "187":{"square":"15.8 кв.м."},
                    "188":{"square":"5.8 кв.м."},
                    "189":{"square":"2.9 кв.м."},
                    "190":{"square":"1.4 кв.м."},
                    "191":{"square":"1.4 кв.м."},
                    "192":{"square":"2.5 кв.м."},
                    "193":{"square":"1.4 кв.м."},
                    "194":{"square":"4.0 кв.м."},
                    "195":{"square":"3.7 кв.м."},
                    "196":{"square":"3.7 кв.м."},
                    "197":{"square":"4.3 кв.м."},
                    "198":{"square":"45.0 кв.м."},
                    "199":{"square":"21.1 кв.м."},
                    "200":{"square":"45.4 кв.м."},
                    "201":{"square":"4.6 кв.м."},
                    "202":{"square":"5.2 кв.м."},
                    "203":{"square":"42.4 кв.м."},
                    "204":{"square":"5.1 кв.м."},
                    "205":{"square":"32.9 кв.м."},
                    "206":{"square":"12.2 кв.м."},
                    "207":{"square":"5.1 кв.м."},
                    "208":{"square":"32.4 кв.м."},
                    "209":{"square":"32.1 кв.м."},
                    "210":{"square":"19.2 кв.м."},
                    "211":{"square":"15.3 кв.м."},
                    "212":{"square":"9.7 кв.м."},
                    "213":{"square":"18.5 кв.м."},
                    "214":{"square":"20.9 кв.м."},
                    "215":{"square":"14.4 кв.м."},
                    "216":{"square":"18.2 кв.м."},
                    "217":{"square":"47.7 кв.м."},
                    "218":{"square":"6.0 кв.м."},
                    "219":{"square":"2.9 кв.м."},
                    "220":{"square":"1.5 кв.м."},
                    "221":{"square":"1.5 кв.м."},
                    "222":{"square":"2.3 кв.м."},
                    "223":{"square":"1.3 кв.м."},
                    " 1":{"parking":true, "square": "Парковочное место 1"},
                    " 2":{"parking":true, "square": "Парковочное место 2"},
                    " 3":{"parking":true, "square": "Парковочное место 3"},
                    " 4":{"parking":true, "square": "Парковочное место 4"},
                    " 5":{"parking":true, "square": "Парковочное место 5"},
                    " 6":{"parking":true, "square": "Парковочное место 6"},
                    " 7":{"parking":true, "square": "Парковочное место 7"},
                    " 8":{"parking":true, "square": "Парковочное место 8"},
                    " 9":{"parking":true, "square": "Парковочное место 9"},
                    " 10":{"parking":true, "square": "Парковочное место 10"},
                    " 11":{"parking":true, "square": "Парковочное место 11"},
                    " 12":{"parking":true, "square": "Парковочное место 12"},
                    " 13":{"parking":true, "square": "Парковочное место 13"},
                    " 14":{"parking":true, "square": "Парковочное место 14"},
                    " 15":{"parking":true, "square": "Парковочное место 15"},
                    " 16":{"parking":true, "square": "Парковочное место 16"},
                    " 17":{"parking":true, "square": "Парковочное место 17"},
                    " 18":{"parking":true, "square": "Парковочное место 18"},
                    " 19":{"parking":true, "square": "Парковочное место 19"},
                    " 20":{"parking":true, "square": "Парковочное место 20"},
                    " 21":{"parking":true, "square": "Парковочное место 21"},
                    " 22":{"parking":true, "square": "Парковочное место 22"},
                    " 23":{"parking":true, "square": "Парковочное место 23"},
                    " 24":{"parking":true, "square": "Парковочное место 24"},
                    " 25":{"parking":true, "square": "Парковочное место 25"},
                    " 26":{"parking":true, "square": "Парковочное место 26"},
                    " 27":{"parking":true, "square": "Парковочное место 27"},
                    " 28":{"parking":true, "square": "Парковочное место 28"},
                    " 29":{"parking":true, "square": "Парковочное место 29"},
                    " 30":{"parking":true, "square": "Парковочное место 30"},
                    " 31":{"parking":true, "square": "Парковочное место 31"},
                    " 32":{"parking":true, "square": "Парковочное место 32"},
                    " 33":{"parking":true, "square": "Парковочное место 33"},
                    " 34":{"parking":true, "square": "Парковочное место 34"},
                    " 35":{"parking":true, "square": "Парковочное место 35"},
                    " 36":{"parking":true, "square": "Парковочное место 36"},
                    " 37":{"parking":true, "square": "Парковочное место 37"},
                    " 38":{"parking":true, "square": "Парковочное место 38"},
                    " 39":{"parking":true, "square": "Парковочное место 39"},
                    " 40":{"parking":true, "square": "Парковочное место 40"},
                    " 41":{"parking":true, "square": "Парковочное место 41"},
                    " 42":{"parking":true, "square": "Парковочное место 42"},
                    " 43":{"parking":true, "square": "Парковочное место 43"},
                    " 44":{"parking":true, "square": "Парковочное место 44"},
                    " 45":{"parking":true, "square": "Парковочное место 45"},
                    " 46":{"parking":true, "square": "Парковочное место 46"},
                    " 47":{"parking":true, "square": "Парковочное место 47"},
                    " 48":{"parking":true, "square": "Парковочное место 48"},
                    " 49":{"parking":true, "square": "Парковочное место 49"},
                    " 50":{"parking":true, "square": "Парковочное место 50"},
                    " 51":{"parking":true, "square": "Парковочное место 51"},
                    " 52":{"parking":true, "square": "Парковочное место 52"},
                    " 53":{"parking":true, "square": "Парковочное место 53"},
                    " 54":{"parking":true, "square": "Парковочное место 54"},
                    " 55":{"parking":true, "square": "Парковочное место 55"},
                    " 56":{"parking":true, "square": "Парковочное место 56"},
                    " 57":{"parking":true, "square": "Парковочное место 57"},
                    " 58":{"parking":true, "square": "Парковочное место 58"},
                    " 59":{"parking":true, "square": "Парковочное место 59"},
                    " 60":{"parking":true, "square": "Парковочное место 60"},
                    " 61":{"parking":true, "square": "Парковочное место 61"},
                    " 62":{"parking":true, "square": "Парковочное место 62"},
                    " 63":{"parking":true, "square": "Парковочное место 63"},
                    " 64":{"parking":true, "square": "Парковочное место 64"},
                    " 65":{"parking":true, "square": "Парковочное место 65"},
                    " 66":{"parking":true, "square": "Парковочное место 66"},
                    " 67":{"parking":true, "square": "Парковочное место 67"},
                    " 68":{"parking":true, "square": "Парковочное место 68"},
                    " 69":{"parking":true, "square": "Парковочное место 69"},
                    " 70":{"parking":true, "square": "Парковочное место 70"},
                    " 71":{"parking":true, "square": "Парковочное место 71"},
                    " 72":{"parking":true, "square": "Парковочное место 72"},
                    " 73":{"parking":true, "square": "Парковочное место 73"},
                    " 74":{"parking":true, "square": "Парковочное место 74"},
                    " 75":{"parking":true, "square": "Парковочное место 75"},
                    " 76":{"parking":true, "square": "Парковочное место 76"},
                    " 77":{"parking":true, "square": "Парковочное место 77"},
                    " 78":{"parking":true, "square": "Парковочное место 78"},
                    " 79":{"parking":true, "square": "Парковочное место 79"},
                    " 80":{"parking":true, "square": "Парковочное место 80"},
                    " 81":{"parking":true, "square": "Парковочное место 81"},
                    " 82":{"parking":true, "square": "Парковочное место 82"},
                    " 83":{"parking":true, "square": "Парковочное место 83"},
                    " 84":{"parking":true, "square": "Парковочное место 84"},
                    " 85":{"parking":true, "square": "Парковочное место 85"},
                    " 86":{"parking":true, "square": "Парковочное место 86"},
                    " 87":{"parking":true, "square": "Парковочное место 87"},
                    " 88":{"parking":true, "square": "Парковочное место 88"},
                    " 89":{"parking":true, "square": "Парковочное место 89"},
                    " 90":{"parking":true, "square": "Парковочное место 90"},
                    " 91":{"parking":true, "square": "Парковочное место 91"},
                    " 92":{"parking":true, "square": "Парковочное место 92"},
                    " 93":{"parking":true, "square": "Парковочное место 93"},
                    " 94":{"parking":true, "square": "Парковочное место 94"},
                    " 95":{"parking":true, "square": "Парковочное место 95"},
                    " 96":{"parking":true, "square": "Парковочное место 96"},
                    " 97":{"parking":true, "square": "Парковочное место 97"},
                    " 98":{"parking":true, "square": "Парковочное место 98"},
                    " 99":{"parking":true, "square": "Парковочное место 99"},
                    " 100":{"parking":true, "square": "Парковочное место 100"},
                    " 101":{"parking":true, "square": "Парковочное место 101"},
                    " 102":{"parking":true, "square": "Парковочное место 102"},
                    " 103":{"parking":true, "square": "Парковочное место 103"},
                    " 104":{"parking":true, "square": "Парковочное место 104"},
                    " 105":{"parking":true, "square": "Парковочное место 105"},
                    " 106":{"parking":true, "square": "Парковочное место 106"},
                    " 107":{"parking":true, "square": "Парковочное место 107"},
                    " 108":{"parking":true, "square": "Парковочное место 108"},
                    " 109":{"parking":true, "square": "Парковочное место 109"},
                    " 110":{"parking":true, "square": "Парковочное место 110"}
                },
                all_mapped_areas: [
                    {}, {}, {}, {}, {}
                ],
                clicked_area_id:null,
                clicked_area_key:null,
                clicked_group_key:null,
                clicked_group_text:null,
                hovered_area_id:null,
                hovered_area_key:null,
                hovered_group_key:null,
                loaded:false,
            }
        },
        methods: {
            floor_image: function () {
                return this.floors[this.floor]['img'];
            },
            draw_area_by_id: function(area_id, style) {
                let fillStyle = "white";
                let strokeStyle = "red";
                //console.log('area_id=', area_id, 'style=', style);
                let group_id =  this.areas[area_id]['group'];
                let status = this.all_groups[this.floor][group_id]['status'];
                //console.log('group_id=', group_id, 'style=', style, 'status=', status);
                switch (style) {
                    case 'hover':
                        fillStyle = this.color(status, true, false);
                        strokeStyle = "black";
                        break;
                    case 'lite':
                        fillStyle = this.color(status, false, true);
                        strokeStyle = "black";
                        break;
                    default:
                        fillStyle = this.color(status, false, false);
                        strokeStyle = "black";
                        break;
                }
                //console.log('fillStyle=', fillStyle,);

                if (this.areas[area_id]['shape']==='rect') {
                    let c = this.areas[area_id]['scoords'];
                    //console.log('rect', c[0], c[1], (c[2] - c[0]), (c[3] - c[1]));
                    this.ctx.beginPath();
                    this.ctx.fillStyle = fillStyle;
                    this.ctx.strokeStyle = strokeStyle;
                    this.ctx.rect(c[0], c[1], (c[2] - c[0]), (c[3] - c[1]));
                    this.ctx.stroke();
                    this.ctx.fillRect(c[0], c[1], (c[2] - c[0]), (c[3] - c[1]));
                }
                else if (this.areas[area_id]['shape']==='poly') {
                    let c = this.areas[area_id]['scoords'];
                    let l = this.areas[area_id]['scoords'].length;
                    this.ctx.beginPath();
                    this.ctx.fillStyle = fillStyle;
                    this.ctx.strokeStyle = strokeStyle;
                    this.ctx.moveTo(c[l-2], c[l-1]);
                    for (let i=0; i<l; i+=2)
                    {
                        this.ctx.lineTo(c[i], c[i+1]);
                    }
                    this.ctx.closePath();
                    this.ctx.fill();
                    this.ctx.stroke();
                }
            },
            draw_area_by_key: function(area_key, style) {
                //console.log('draw_area_by_key', area_key)
                this.draw_area_by_id(this.mapped_areas[area_key], style);
            },
            draw_group: function(group_key, style) {
                //console.log('draw_group', group_key)
                //console.log('group', this.groups[group_key])
                //console.log('group.areas', this.groups[group_key].areas)
                for (let area_key of this.groups[group_key].areas) {
                    //console.log('area_key', area_key)
                    this.draw_area_by_key(area_key, style)
                }
            },
            updateCanvas: function (){
                //console.log('updateCanvas')
                this.ctx.clearRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
                //console.log('clearRect', 0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
                for (let group in this.all_groups[this.floor]) {
                    if (this.hovered_group_key == group) {
                        this.draw_group(group, 'hover')
                    }
                    else {
                        this.draw_group(group, 'normal')
                    }
                }

                // if (this.hovered_area_id !== null) {
                //     this.draw_area_by_id(this.hovered_area_id, 'hover')
                // }
            },
            cleanCanvas: function (){
                //console.log('updateCanvas')
                this.ctx.clearRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
            },
            showCoordinates(e) {
                this.mouseX = e.offsetX;
                this.mouseY = e.offsetY;
            },
            _click: function(i){
                //console.log('_click', i)
                this.clicked_area_id = i;
                this.clicked_area_key = this.all_areas[this.floor][i]['title'];
                this.clicked_group_key = this.all_areas[this.floor][i]['group'];
                this.clicked_group_text = this.all_groups[this.floor][this.clicked_group_key]['title'];
            },
            // _mouseover: function(i){
            //     //console.log('_mouseover', i)
            // },
            _mouseenter: function(i){
                //console.log('_mouseenter', i)
                this.hovered_area_id = i;
                //console.log('this.hovered_area_id', this.hovered_area_id)
                this.hovered_area_key = this.all_areas[this.floor][i]['title'];
                //console.log('this.hovered_area_key', this.hovered_area_key)
                this.hovered_group_key = this.all_areas[this.floor][i]['group'];
                //console.log('this.hovered_group_key', this.hovered_group_key)
                this.updateCanvas();
            },
            _mouseleave: function(){
                //console.log('_mouseleave', i)
                this.hovered_area_id = null;
                this.hovered_area_key = null;
                this.hovered_group_key = null;
                this.updateCanvas();
            },
            color: function (status, hover, lite) {
                if (lite) {
                    switch (status) {
                        case '1': return 'rgba(255, 63, 98, 0.7)'; //Арендовано (1)(розовый)
                        case '2': return 'rgba(36, 181, 0, 0.7)'; //Свободно (2)(зеленый)
                        case '3': return 'rgba(128, 128, 128, 0.7)'; //Прочие помещения (3)(серый)
                        case '4': return 'rgba(255, 221, 0, 0.7)'; //Прочие помещения  4(жёлтый),
                        case '5': return 'rgba(0, 174, 255, 0.7)'; //Прочие помещения  5(голубой),
                        case '6': return 'rgba(204, 0, 211, 0.7)'; //Прочие помещения
                        default: return 'rgba(128, 128, 128, 0.7)'; //Прочие помещения (3)(серый)
                    }
                }
                if (hover) {
                    switch (status) {
                        case '1': return 'rgba(255, 63, 98, 0.8)'; //Арендовано (1)(розовый)
                        case '2': return 'rgba(36, 181, 0, 0.8)'; //Свободно (2)(зеленый)
                        case '3': return 'rgba(128, 128, 128, 0.8)'; //Прочие помещения (3)(серый)
                        case '4': return 'rgba(255, 221, 0, 0.8)'; //Прочие помещения  4(жёлтый),
                        case '5': return 'rgba(0, 174, 255, 0.8)'; //Прочие помещения  5(голубой),
                        case '6': return 'rgba(204, 0, 211, 0.8)'; //Прочие помещения
                        default: return 'rgba(128, 128, 128, 0.8)'; //Прочие помещения (3)(серый)
                    }
                }
                else {
                    switch (status) {
                        case '1': return 'rgba(255, 63, 98, 0.5)'; //Арендовано (1)(розовый)
                        case '2': return 'rgba(36, 181, 0, 0.5)'; //Свободно (2)(зеленый)
                        case '3': return 'rgba(128, 128, 128, 0.5)'; //Прочие помещения (3)(серый)
                        case '4': return 'rgba(255, 221, 0, 0.5)'; //Прочие помещения  4(жёлтый),
                        case '5': return 'rgba(0, 174, 255, 0.5)'; //Прочие помещения  5(голубой),
                        case '6': return 'rgba(204, 0, 211, 0.5)'; //Прочие помещения
                        default: return 'rgba(128, 128, 128, 0.5)'; //Прочие помещения (3)(серый)
                    }
                }
            },
            group_title:function(group) {
                if (this.all_groups && group) {
                    return 'Помещение № ' + group + ', ' + this.all_groups[this.floor][group]['title'];
                }
                else {
                    return '';
                }
            },
            reset_clicked: function () {
                this.clicked_area_id=null;
                this.clicked_area_key=null;
                this.clicked_group_key=null;
                this.clicked_group_text=null;
            },
            fetch_data(){
                let url = '/store/groups.json';
                let self = this;
                fetch( url, {
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => response.text())
                .then( text => {
                    let jsond = JSON5.parse(text);
                    self.all_groups = jsond
                    self.init_data()
                    self.init_canvas()
                }, error => {
                    console.log('fetch_data error', error)
                    self.init_data()
                    self.init_canvas()
                });
            },
            init_data: function () {
                //console.log('init_data');
                for (let floor=0; floor<FLOORS; floor++) {
                    for (let i = 0; i < this.all_areas[floor].length; i++) {
                        this.all_areas[floor][i]['scoords'] = this.all_areas[floor][i]['_coords'].split(',')
                        for (let j = 0; j < this.all_areas[floor][i]['scoords'].length; j++) {
                            this.all_areas[floor][i]['scoords'][j] = Math.round(this.all_areas[floor][i]['scoords'][j] / 4);
                        }
                        this.all_areas[floor][i]['coords'] = this.all_areas[floor][i]['scoords'].join(',');
                        this.all_mapped_areas[floor][this.all_areas[floor][i]['title']] = i;
                    }
                }
                for (let floor=0; floor<FLOORS; floor++) {
                    //console.log('init floor=', floor)
                    for (let group in this.all_groups[floor]) {
                        //console.log('   init group=', group)
                        for (let j = 0; j < this.all_groups[floor][group]['areas'].length; j++) {
                            let area_id = this.all_groups[floor][group]['areas'][j];
                            //console.log('      init j=', j, area_id)
                            this.all_areas[floor][this.all_mapped_areas[floor][area_id]]['group'] = group;
                        }
                    }
                }
                this.loaded = true;
            },
            init_canvas: function () {
                //console.log('init_canvas');
                this.canvas = document.getElementById('plan-mapper-canvas');
                this.canvas.width = IMAGE_SCREEN_WIDTH;
                this.canvas.height = IMAGE_SCREEN_HEIGHT;
                this.ctx = this.canvas.getContext('2d');
                this.updateCanvas();
            },
            area_square_info: function (area_title) {
                if (!this.squares[area_title]) {
                    return 'уточняйте у менеджера';
                }
                if (this.squares[area_title]['parking']) {
                    return this.squares[area_title]['square'];
                }
                return 'Помещение ' + area_title + ': ' + this.squares[area_title]['square'];
            }
        },
        computed: {
            image_width: function () {
                return IMAGE_WIDTH/4;
            },
            image_height: function () {
                return IMAGE_HEIGHT/4;
            },
            image_screen_width: function () {
                return IMAGE_SCREEN_WIDTH;
            },
            image_screen_height: function () {
                return IMAGE_SCREEN_HEIGHT;
            },
            areas: function () {
                return this.all_areas[this.floor];
            },
            groups: function () {
                return this.all_groups[this.floor];
            },
            mapped_areas: function () {
                return this.all_mapped_areas[this.floor];
            },
        },
        watch: {
            floor: function() {
                this.cleanCanvas();
                this.updateCanvas();
            }
        },
        updated() {
            //console.log('updated')
            //this.updateCanvas();
        },
        created() {
            this.fetch_data();
        },
        mounted() {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    div#plan-mapper-container {
        /* overflow: hidden; */
        width:690px;
        height:765px;
        position:relative;
        margin: 0 auto 0 auto;
    }
    img#plan-mapper-img {
        width:690px;
        height:765px;
        position:relative;
    }
    canvas#plan-mapper-canvas{
        pointer-events: none;
        width:690px;
        height:765px;
        position:absolute;
        z-index: 2;
    }
    div#plan-mapper-infobg{
        top:0;
        left:0;
        width:690px;
        height:765px;
        position:absolute;
        z-index: 3;
        background-color: rgba(0,0,0,0.3);
    }
    div#plan-mapper-info{
        width:250px;
        height:150px;
        position:absolute;
        left:220px;
        top:308px;
        z-index: 4;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
</style>
